import { Button } from 'components/common/buttons/Button';
import { EngineChannel } from 'components/inputs/EngineChannel';
import { FlexBox } from 'components/layouts/FlexBox';
import { Heading } from 'components/typography';
import { useState } from 'react';
import { Channel, getDefaultReleaseChannel } from 'utils/releaseChannel';
import { Modal } from '../Modal';

export const EngineVersionModal = ({
  handleJoin,
  close,
  props,
}: {
  close?: () => void;
  handleJoin: (projectId: string, channel: Channel) => void;
  props?: { projectId: string };
}) => {
  const { projectId } = props ?? {};

  const [engineChannel, setEngineChannel] = useState<Channel>(getDefaultReleaseChannel());

  const onClick = () => {
    handleJoin(projectId || '', engineChannel);
    close?.();
  };
  return (
    <Modal>
      <Heading size="h3" as="h3" marginBottom="2rem" textAlign="center">
        Select engine version
      </Heading>
      <EngineChannel engineChannel={engineChannel} setEngineChannel={setEngineChannel} />
      <FlexBox justifyContent="center" marginTop="1rem">
        <Button variety="primary" size="medium" onClick={onClick} text="Go!" />
      </FlexBox>
    </Modal>
  );
};
