import { Exact, MatchmakeQuery, MatchmakeRequest, useMatchmakeLazyQuery } from 'generated/graphql';

import { LazyQueryHookOptions } from '@apollo/client';

export const useMatchMake = (
  options?: LazyQueryHookOptions<
    MatchmakeQuery,
    Exact<{
      request: MatchmakeRequest;
    }>
  >
) => {
  const [matchMake, matchMakeResult] = useMatchmakeLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const sessionUrl = data?.matchMake?.sessionUrl;

      if (sessionUrl) {
        window.location.href = sessionUrl;
      }
    },
    ...options,
  });

  const handleMatchMake = (request: MatchmakeRequest) => {
    return matchMake({
      variables: {
        request: { clientNetworkProtocolVersion: null, clientVersion: null, ...request },
      },
    });
  };

  return [handleMatchMake, matchMakeResult] as const;
};
