import styled, { css } from 'styled-components';

export const SplashPageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .splash-page-logo {
    width: 350px;
  }

  .splash-page-logo img {
    width: 100%;
  }
  .splash-page-down {
    margin: 0;
    background: url(https://cdn.hibervr.com/static/images/down-for-maintenance.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-position: 20% 50%;
    @media screen and (max-width: 1024px) {
      background-position: 20% 50%;
    }
    @media screen and (max-width: 850px) {
      background-size: cover;
      background-position: 15% 50%;
    }
    @media screen and (max-width: 550px) {
      background-position: 20% 50%;
    }
  }
`;

const logoTopLeftStyles = css`
  margin: 20px 0 0 20px;
  width: 100%;
  max-width: 300px;
  background: url(//cdn.hibervr.com/static/logos/HiberWorld-logo-1-inline-row-trans.png);
  background-repeat: no-repeat;
  background-size: cover;

  &.splash-page-error {
    margin: 0;
    background: url(//cdn.hibervr.com/static/logos/404.png);
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-position: 20% 50%;
    @media screen and (max-width: 1024px) {
      background-position: 20% 50%;
    }
    @media screen and (max-width: 850px) {
      background-size: cover;
      background-position: 15% 50%;
    }
    @media screen and (max-width: 550px) {
      background-position: calc(-35vw - 100px) 50%;
    }
    .splash-page-logo {
      padding-bottom: 80%;
    }
    a:link,
    a {
      color: ${({ theme }) => theme.primaryColor.red};
    }
  }

  .splash-page-logo {
    padding-bottom: 27.13%;
  }

  @media (max-width: ${props => props.theme.sass.break_small}) {
    max-width: 200px;
  }
`;

export const SplashLogoTopLeft = styled.div`
  ${logoTopLeftStyles}
`;

export const SplashLogoTopLeftLink = styled.a`
  ${logoTopLeftStyles}
`;

export const SplashWrapper = styled.div`
  margin: 0 auto 0 auto;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 250px);
  &.splash-page-error {
    z-index: 1;
    justify-content: flex-start;
    a:link,
    a {
      color: ${({ theme }) => theme.primaryColor.red};
    }
    div {
      padding-top: 15rem;
      padding-left: 50%;
      display: flex;
      flex-direction: column;
      @media (max-width: 1024px) {
        padding-top: 2rem;
        padding-left: 0;
      }
    }
  }
  :global(article) {
    margin: 50px 0 50px 0;
  }
`;
