import { ErrorPage } from 'views/pages/error/Error';
import { ErrorPageProps } from 'views/pages/error/Error.types';

const Error = ({ statusCode, errorMessage, title }: ErrorPageProps) => {
  return <ErrorPage statusCode={statusCode} errorMessage={errorMessage} title={title} />;
};

Error.getInitialProps = async ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null;

  // This will contain the status code of the response
  return { statusCode };
};

export default Error;
