import { Completion, Play } from '@hiberworld/icons';
import { FlexBox } from 'components/layouts/FlexBox';
import { Link } from 'components/common/Link';
import { Ribbon } from 'components/common/Ribbon';
import { Spacer } from 'components/layouts/Spacer';
import { Label } from 'components/typography';
import { VerifiedBadge } from 'components/globals/VerifiedBadge';
import { GameKit } from 'generated/graphql';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import { ReactElement } from 'react';
import Base64 from 'utils/Base64';
import { formatNumber } from 'utils/formatNumber';
import { formatPercentage } from 'utils/formatPercentage';
import * as S from './ProjectCard.styles';
import { ProjectCardProps } from './ProjectCard.types';
import { Box } from 'components/layouts/Box/Box';

const NothingOrLink = ({
  children,
  title,
  href,
  noLink,
}: {
  children: ReactElement;
  title: string;
  href: string;
  noLink?: boolean;
}) => {
  if (noLink) {
    return children;
  }

  return (
    <Link href={href} passHref aria-label={`Visit ${title}`} prefetch={false}>
      {children}
    </Link>
  );
};

export const ProjectCard = ({
  project,
  trackEvent,
  showAuthor,
  dataCy,
  playOnClick,
  onAuthorClick,
  onProjectClick,
  hideTitle,
  hideStats,
}: ProjectCardProps) => {
  const { id, title, thumbnail, author, featured, totalViews, completionRate, gameKit } = project;
  const session = useGetOrCreateDaoSession();

  const joinSession = () => {
    session.loadAndJoinSession(project.id);
  };

  if (author.id === '-1') {
    // If the project author is invalid (deleted or blocked), don't show the card
    return null;
  }

  return (
    <Box
      marginBottom="0.5rem"
      position="relative"
      data-testid="project-card"
      data-cy={dataCy}
      onClick={() => {
        trackEvent?.();
      }}>
      <NothingOrLink href={`/world/${Base64.fromNumber(id)}`} title={title} noLink={playOnClick}>
        <S.ImageContainer onClick={onProjectClick}>
          <S.Thumbnail src={thumbnail?.medium?.url ?? ''} alt="Click to see world" />
          {featured && <Ribbon text="featured" color="purple" />}
          {playOnClick && (
            <S.PlayOverlay
              width="100%"
              height="100%"
              position="absolute"
              justifyContent="center"
              alignItems="center"
              onClick={joinSession}>
              <S.RoundButton
                busy={session.loading}
                disabled={session.loading}
                text="Join"
                textStyle="large"
                iconLeft={<S.PlayIcon title="Play game" />}
              />
            </S.PlayOverlay>
          )}
          {!hideStats && (
            <Label size="specialSmall">
              <S.Overlay alignItems="center">
                <Play />
                {formatNumber(totalViews)}
                {gameKit === GameKit.Platformer && (
                  <>
                    <Spacer width={8} />
                    <Completion />
                    {formatPercentage(completionRate)}
                  </>
                )}
              </S.Overlay>
            </Label>
          )}
        </S.ImageContainer>
      </NothingOrLink>
      <S.MetaInfoWrapper>
        {showAuthor && (
          <Link href={`/user/${author.username}`} passHref legacyBehavior prefetch={false}>
            <S.ProfileImage
              onClick={onAuthorClick}
              src={author.pictureUrl}
              alt="Visit creators profile"
              height={40}
              width={40}
            />
          </Link>
        )}
        {!hideTitle && (
          <FlexBox direction="column" height="20px" width="92%">
            {!showAuthor && (
              <Link href={`/world/${Base64.fromNumber(id)}`} passHref prefetch={false}>
                <S.Title data-testid="project-card-title" onClick={onProjectClick}>
                  {title}
                </S.Title>
              </Link>
            )}
            {showAuthor && (
              <FlexBox onClick={onAuthorClick} direction="column">
                <Link href={`/world/${Base64.fromNumber(id)}`} passHref prefetch={false}>
                  <S.Title data-testid="project-card-title" onClick={onProjectClick}>
                    {title}
                  </S.Title>
                </Link>
                <FlexBox gap="4px">
                  <Link href={`/user/${author.username}`} prefetch={false}>
                    <S.Author>{author.username}</S.Author>
                  </Link>
                  <FlexBox>
                    <VerifiedBadge isEmployee={author.isEmployee} size={20} />
                    <VerifiedBadge isVerified={author.isVerified} size={20} />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        )}
      </S.MetaInfoWrapper>
    </Box>
  );
};
