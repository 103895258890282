import React from 'react';
import * as S from './Ribbon.styles';
import { RibbonProps } from './Ribbon.types';

export const Ribbon = ({ text, color, yOffSet }: RibbonProps) => {
  return (
    <S.RibbonBannerContainer data-testid="featured-banner" yOffSet={yOffSet}>
      <S.RibbonBanner color={color}>{text}</S.RibbonBanner>
    </S.RibbonBannerContainer>
  );
};
