import styled from 'styled-components';
import { RibbonStylesProps } from './Ribbon.types';

export const RibbonBannerContainer = styled.div<Pick<RibbonStylesProps, 'yOffSet'>>`
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  bottom: ${({ yOffSet }) => 0 + (yOffSet ?? 0)}px;
  right: -1px;
  pointer-events: none;
`;

export const RibbonBanner = styled.div<Pick<RibbonStylesProps, 'color'>>`
  text-transform: uppercase;
  width: 200px;
  padding: 8px;
  background-color: ${({ theme, color }) => theme.primaryColor[color]};
  color: ${({ theme }) => theme.primaryColor.white};
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 12px;
  transform: rotate(-45deg) translate(-58px, 82px);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
`;
