import { FlexBox } from 'components/layouts/FlexBox';
import { LoadingCard, CirclePlaceholder, TextPlaceholder } from 'components/common/SkeletonLoader';

export const ProjectCardLoader = ({ hideTitle, hideAuthor }: { hideTitle?: boolean; hideAuthor?: boolean }) => {
  return (
    <FlexBox direction="column" aria-label="loading worlds...">
      <LoadingCard height="100%" style={{ maxHeight: '170px' }} borderRadius={8} aspectRatio="210 / 130" />
      {!hideTitle && (
        <FlexBox gap="8px" alignItems="flex-start" margin="8px 0 0">
          {!hideAuthor && <CirclePlaceholder size={40} />}
          <FlexBox width="80%" direction="column" height="100%" gap="0.25rem">
            <TextPlaceholder width="calc(90% - 20px)" />
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
};
