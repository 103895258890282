import { Link } from 'components/common/Link';
import { Heading, Paragraph } from 'components/typography';
import { ErrorLayout } from 'views/global/ErrorLayout';
import { ErrorPageProps } from './Error.types';

export const ErrorPage = ({ statusCode, errorMessage, title }: ErrorPageProps) => {
  return (
    <ErrorLayout>
      <div>
        <Heading size="h2" as="h1" marginBottom="2rem">
          {title || (
            <>
              {statusCode === 404 && 'Looks like you got lost wandering the HiberWorld'}
              {!statusCode && 'An error occurred on client'}
              {!!statusCode && statusCode !== 404 && `${statusCode} occurred on server`}
            </>
          )}
        </Heading>
        {errorMessage && <Paragraph size="large">Error: {errorMessage}</Paragraph>}
        {statusCode === 404 && (
          <>
            <Paragraph size="large" marginTop="2rem">
              Let us help you get back to the <Link href="/">start page</Link>
            </Paragraph>
            <Paragraph size="large">
              or contact us at <a href="mailto:hello@hiber3d.com">hello@hiber3d.com</a>
            </Paragraph>
          </>
        )}
      </div>
    </ErrorLayout>
  );
};
