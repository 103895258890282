import cn from 'classnames';
import { FlexBox } from 'components/layouts/FlexBox';
import { Head } from 'components/common/Head';
import { Link } from 'components/common/Link';
import React from 'react';
import { SplashLogoTopLeft, SplashLogoTopLeftLink, SplashPageWrapper, SplashWrapper } from './Splash.styles';
import { SplashProps } from './splash.types';

const Splash: React.FC<SplashProps> = ({ children, title, showLogo, showErrorLogo, showDown, referrer = null }) => (
  <SplashPageWrapper>
    <Head title={title} referrer={referrer} />

    {showLogo && (
      <Link href="/">
        <img src="https://cdn.hibervr.com/static/logos/HiberWorld-logo-1-inline-row-trans.png" alt="" />
      </Link>
    )}

    {showErrorLogo && <SplashLogoTopLeft className={cn('splash-page-logo', 'splash-page-error')} />}

    {showErrorLogo && (
      <Link href="/" passHref legacyBehavior>
        <SplashLogoTopLeftLink />
      </Link>
    )}

    {!showDown && <SplashWrapper className={showErrorLogo ? 'splash-page-error' : ''}>{children}</SplashWrapper>}

    {showDown && <div className="splash-page-down">{children}</div>}

    <footer>
      <FlexBox justifyContent="center">
        {!showErrorLogo && !showDown && <Link href="mailto:hello@hiber3d.com">hello@hiber3d.com</Link>}
      </FlexBox>
    </footer>
  </SplashPageWrapper>
);

export default Splash;
